
import { Component, Vue } from 'vue-property-decorator';
import ResetPassword from './entities/reset-password.entity';
import AlertErrorComponent from '../../components/alert-error/alert-error.component.vue';

@Component({ components: { AlertErrorComponent } })
export default class NewPasswordComponent extends Vue {
  private password: string = '';

  private passwordConfirmation: string = '';

  private resetPassword = new ResetPassword();

  private errors: object = {};

  private rules: any = {
    required: (value: any) => !!value || 'Campo obrigatório.',
    minLengthEight: (value: any) => value.length >= 8 || 'Senha deve ter no mínimo 8 caracteres.',
    isEqualPassword: this.isEqualPasswords,
  };

  private get passwordIsEquals() {
    return this.password && this.password === this.passwordConfirmation;
  }

  private get disableConfirmPassword(): boolean {
    return this.password.length < 8;
  }

  private get hasError(): boolean {
    return Object.keys(this.errors).length > 0;
  }

  private isEqualPasswords() {
    if (!this.passwordConfirmation || !this.password) {
      return true;
    }
    return this.passwordConfirmation === this.password || 'As senhas estão diferentes';
  }

  private seNewPassword(): void {
    const { userId, payload } = this.getDataFromUrl();
    this.resetPassword = new ResetPassword(payload);
    this.resetPassword
      .reset(userId)
      .then(() => {
        this.$router.push({
          name: 'login',
        });
      })
      .catch((err) => {
        this.errors = err.response.data.errors;
      });
  }

  private getDataFromUrl() {
    const { userId } = this.$route.params;
    const { expires, signature } = this.$route.query;
    return {
      userId,
      payload: {
        expires,
        signature,
        password: this.password,
        passwordConfirmation: this.passwordConfirmation,
      },
    };
  }
}
