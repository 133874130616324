import { SetPasswordInterface } from '@/modules/Administrative/modules/Users/interfaces/reset-password.interface';
import userService from '@/modules/Administrative/modules/Users/services/user.service';
import { ResetPasswordInterface } from '../interfaces/reset-password.interface';

export default class ResetPassword implements ResetPasswordInterface {
  public passwordConfirmation = '';

  public password = '';

  public signature = '';

  public expires = '';

  constructor(data: any = {}) {
    this.passwordConfirmation = data.passwordConfirmation;
    this.password = data.password;
    this.signature = data.signature;
    this.expires = data.expires;
  }

  public reset(userId: string) {
    return userService.setPassword(userId, this.format());
  }

  public format(): SetPasswordInterface {
    return {
      ...this,
      password_confirmation: this.passwordConfirmation,
    };
  }
}
